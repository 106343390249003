// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-banking-js": () => import("./../src/pages/banking.js" /* webpackChunkName: "component---src-pages-banking-js" */),
  "component---src-pages-consumer-js": () => import("./../src/pages/consumer.js" /* webpackChunkName: "component---src-pages-consumer-js" */),
  "component---src-pages-elements-js": () => import("./../src/pages/elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-energy-js": () => import("./../src/pages/energy.js" /* webpackChunkName: "component---src-pages-energy-js" */),
  "component---src-pages-generic-js": () => import("./../src/pages/generic.js" /* webpackChunkName: "component---src-pages-generic-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-others-js": () => import("./../src/pages/others.js" /* webpackChunkName: "component---src-pages-others-js" */),
  "component---src-pages-realestate-js": () => import("./../src/pages/realestate.js" /* webpackChunkName: "component---src-pages-realestate-js" */),
  "component---src-pages-services-js": () => import("./../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-telcos-js": () => import("./../src/pages/telcos.js" /* webpackChunkName: "component---src-pages-telcos-js" */),
  "component---src-pages-tourism-js": () => import("./../src/pages/tourism.js" /* webpackChunkName: "component---src-pages-tourism-js" */)
}

